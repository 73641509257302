<template>
  <div class="liveDetails">
    <van-nav-bar title="视频详情" >
      <template #left>
        <van-icon :size="24" @click="onCancel()" color="#141212" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>

    <div class="liveDeta_audio">
      <!--<video-player  class="video-player vjs-custom-skin"
                     ref="videoPlayer"
                     :playsinline="true"
                     :options="playerOptions"
      ></video-player>-->
      <video controls :src="playerOptions.sources[0].src" :poster="playerOptions.poster"></video>
    </div>

    <div class="td_title">
      <div class="td_title_head">
        <div class="td_title_head_left">
          <span>{{video.name}}</span>

        </div>
      </div>
      <div class="td_title_nav">
        <span>景点介绍</span>
        <div class="td_title_nav_audio" @click="play()" v-if="video && video.audiobook">
          <img src="./img/maikefeng.png" alt="">
          <span>语音讲解</span>
        </div>
      </div>
      <div class="td_title_cont">
        {{video.synopsis}}
      </div>
    </div>

    <div class="liveDetails_title" v-if="tickets&&tickets.length">相关票务</div>
    <div class="td_tiket_list" v-for="(item, index) in tickets" :key="index">
      <div class="td_tiket_list_head">
        <span>{{item.name}}</span>
        <span>￥{{item.sellPrice ? item.sellPrice/100 : 0}}</span>
      </div>
      <div class="td_tiket_list_bot">
        <span>身份证检票入园      随时可退</span>
        <span>原价 ￥{{item.shopPrice ? item.shopPrice/100 : 0}}</span>
      </div>
    </div>
    <div class="liveDetails_title" v-if="ways&&ways.length">相关路线</div>
    <div class="le_cont_list">
      <div class="le_cont_list_item"  v-for="(item, index) in ways" :key="index"  @click="detail(item.groupId)">
        <van-image :src="item.cover" class="ticket_img">
          <template v-slot:error>加载失败</template>
        </van-image>
        <div class="le_cont_list_item_right">
          <div class="le_cont_list_item_right_title">
            {{item.title}}
          </div>
          <div class="le_cont_list_item_right_bottom">
            <span>{{item.readNumber}}人感兴趣</span>
            <!--<span>¥{{item.showPrice ? item.showPrice : 0}}</span>-->
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script src="./liveDetails.js">

</script>
<style>

</style>
<style scoped src="./liveDetails.css">

</style>
